export const standardPolicy = () => {
    return `<div class="form-group col-12">
    <div class="policy-item-wrp">
        <h6 class="card-label-text-left mb-2">
            <i>Cancellation policy</i>
        </h6>
        <ul>
            <li class="ms-2">
                We offer cancellations on purchases made of the services offered on our Website. We offer cancellations only prior to performance of the service. You may cancel your order by contacting us via email or phone.
            </li>
            <li class="ms-2">
                We reserve the right to cancel your purchase for any reason, at our sole discretion, including but not limited to fraud, inaccuracies, and unavailability of the items or services purchased. We will let you know immediately if we plan on canceling your purchase.
            </li>
            <li class="ms-2">
                We will issue a partial refund of the purchase price that you paid if we cancel your purchase.
            </li>
        </ul>
        <br>
        <h6 class="card-label-text-left mb-2">
            <i>Refund policy</i>
        </h6>
        <ul>
            <li class="ms-2">
                We offer full refunds of ticket price (not booking fees) made of the services offered on our Website. Booking Fees are non-refundable. To qualify for a full refund of the ticket price, you must submit your request to us via phone or email 48 hours prior to the day you booked for. If your booking is canceled within the 48 hour timeframe of your tour, you may be eligible for a 50% refund. If you miss your tour for any reason, you will not be given a refund.
            </li>
            <li class="ms-2">
                In the event that tours are canceled due to weather or unforeseen circumstances, you will receive a full refund.
            </li>
        </ul>
    </div>
</div>`;
}

export const kensPolicy = () => {
    return `<div class="form-group col-12">
    <div class="policy-item-wrp">
        <h6 class="card-label-text-left mb-2">
            <i>Cancellation policy</i>
        </h6>
        <ul>
            <li class="ms-2">
                Please check-in 30 minutes prior from your confirmed tour time. General tours line up 10 minutes before departure. All Groups who have NOT checked-in and received their tour ticket 10 minutes prior to the scheduled departure of the tour, forfeit their reservation and those spaces will be filled with walk-ins. NO REFUNDS will be given in this situation.
            </li>
        </ul>
        <br>
        <h6 class="card-label-text-left mb-2">
            <i>Refund policy</i>
        </h6>
        <ul>
            <li class="ms-2">
                By checking this box, I have read, understand, and agree to the Cancellation Policy: Cancellations must be made 72 hours prior to tour departure date / time for a refund. We offer full refunds of ticket price (not booking fees) made of the services offered on our Website. Booking Fees are non-refundable. There is no refund for cancellations within 72 hours of the scheduled time of tour departure.
            </li>
        </ul>
        <br>
        <h6 class="card-label-text-left mb-2">
            Weather
        </h6>
        <ul>
            <li class="ms-2">
                Ken's Tours reserves the right to cancel tours due to inclement weather for the safety of our guests and employees. During rain/weather cancellations, if your scheduled tour has departed, you will forfeit your tour and fees. NO REFUNDS will be given in this situation. If Ken's Tours cancels tours due to weather prior to your tour's departure, you will receive a full refund.
            </li>
        </ul>
    </div>
</div>`
}

export const apmPolicy = () => {
    return `<div class="form-group col-12">
    <div class="policy-item-wrp">
        <h6 class="card-label-text-left mb-2">
            <i>Cancellation policy</i>
        </h6>
        <ul>
            <li class="ms-2">
                We offer cancellations on purchases made of the services offered on our Website. We offer cancellations only prior to performance of the service. You may cancel your order by contacting us via email or phone.
            </li>
            <li class="ms-2">
                We reserve the right to cancel your purchase for any reason, at our sole discretion, including but not limited to fraud, inaccuracies, and unavailability of the items or services purchased. We will let you know immediately if we plan on canceling your purchase.
            </li>
            <li class="ms-2">
                We will issue a partial refund of the purchase price that you paid if we cancel your purchase.
            </li>
        </ul>
        <br>
        <h6 class="card-label-text-left mb-2">
            <i>Refund policy</i>
        </h6>
        <ul>
            <li class="ms-2">
                We offer full refunds of ticket price (not booking fees) made of the services offered on our Website. Booking Fees are non-refundable. To qualify for a full refund of the ticket price, you must submit your request to us via phone or email 48 hours prior to the day you booked for. If you miss your tour for any reason, you will not be given a refund.
            </li>
            <li class="ms-2">
                In the event that tours are canceled due to weather or unforeseen circumstances, you will receive a full refund.
            </li>
        </ul>
    </div>
</div>`
}